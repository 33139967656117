<template>
  <div>
    <ListingLayout
      :is-loading="isLoading"
      :meta="meta"
      :headers="_headers"
      :items="list"
      :product-disable="true"
      :filter-names="filterNames"
      search-label="search"
      vertical-scroll
      @filterOption="onFiltersChange"
    >
      <template v-slot:actions="{ item }">
        <v-icon
          v-if="isView"
          class="mr-2"
          small
          @click="editItem(item)"
        >
          mdi-eye
        </v-icon>
        <v-icon
          v-if="isEdit"
          class="mr-2"
          small
          @click="editItem(item)"
        >
          mdi-pencil
        </v-icon>
        <v-icon
          v-if="isDelete"
          class="mr-2"
          small
          @click="deleteItem(item)"
        >
          mdi-delete
        </v-icon>
      </template>

      <!-- add Filters -->
      <!-- <template #addFilters>
        <v-card v-if="authUser.isAdmin">

        </v-card>
      </template> -->
      <!-- add Filters -->

      <!-- Action Modals -->
      <template #actionModals>
        <div class="d-flex">
          <v-btn
            v-if="$admin.can('locations.map')"
            color="primary"
            class="map-button"
            :class="isLoading || !list.length > 0 ? 'btn-disabled' : ''"
            dark
            small
            @click="toggelLocationsModal"
          >
            <v-icon>mdi-eye</v-icon>
          </v-btn>
          <create-modal
            v-if="isCreate"
            :filter-options="options"
          />
          <edit-modal
            v-if="(isView || isEdit) && editModal"
            :is-visible-dialog="editModal"
            :toggle-edit="toggleEditModal"
            :item="selectedRow"
          />
          <confirmation-modal
            v-if="isDelete"
            :is-visible="deleteModal"
            :toggle="toggleDeleteModal"
            :row="selectedRow"
          />
          <!--  -->
          <locations-modal
            v-if="$admin.can('locations.map') && locationModal"
            :is-visible-dialog="locationModal"
            :toggle-edit="toggelLocationsModal"
            :items="list"
          />
        </div>
      </template>
      <!-- Action Modals -->
    </ListingLayout>

    <!-- <section>
      <locations-map />
    </section> -->
  </div>
</template>

<script>
import { ListingLayoutMixins } from "@/mixins/ListingLayoutMixins";
import { mapGetters } from "vuex";
// import { debounce } from "lodash";
export default {
  components: {
    ListingLayout: () => import("@/layouts/section/ListingLayout.vue"),
    LocationsModal: () => import("./dialogs/LocationsModal.vue"),
    CreateModal: () => import("./dialogs/CreateLocations.vue"),
    EditModal: () => import("./dialogs/EditLocations.vue"),
    ConfirmationModal: () =>
      import("@/components/Modals/ConfirmationModal.vue"),
  },
  mixins: [ListingLayoutMixins],

  data() {
    return {
      // module_name: "csmsChargeStations", //vuex module name which define in Store Index
      permissions: {
        view: "locations.view",
        create: "locations.create",
        edit: "locations.edit",
        delete: "locations.delete",
      },

      //
      locationModal: false,
      //
      filters: {},
      subFilters: {},
    };
  },
  computed: {
    ...mapGetters({
      authUser: "getUser",
      isLoading: "csmsLocations/getLoading",
      meta: "csmsLocations/getMeta",
      list: "csmsLocations/getList",
    }),
    _headers() {
      const headers = [
        {
          text: this.$t("id"),
          align: "left",
          sortable: true,
          value: "id",
        },
        {
          text: this.$t("name"),
          align: "left",
          sortable: false,
          value: "name",
        },
        {
          text: this.$t("tenant_name"),
          align: "left",
          sortable: false,
          value: "tenant_name",
          showAdmin: true,
        },
        ...(this.authUser.user_nature == "fleet"
          ? [
            {
              text: this.$t("manufacturer"),
              align: "left",
              sortable: false,
              value: "tenant_name",
            },
          ]
          : []),
        {
          text: this.$t("address"),
          align: "left",
          sortable: false,
          value: "address",
        },
        {
          text: this.$t("country"),
          align: "left",
          sortable: false,
          value: "country",
        },
        {
          text: this.$t("city"),
          align: "left",
          sortable: false,
          value: "city",
        },
        {
          text: this.$t("state"),
          align: "left",
          sortable: false,
          value: "state",
        },
        {
          text: this.$t("timings"),
          align: "left",
          sortable: false,
          value: "timings",
        },
        {
          text: this.$t("status"),
          align: "left",
          sortable: false,
          value: "location_status",
        },
        ...(this.isView || this.isEdit || this.isDelete
          ? [
            {
              text: this.$t("actions"),
              align: "left",
              sortable: false,
              value: "actions",
            },
          ]
          : []),
      ];
      // computed headers for showing columns to role based
      return headers.filter(
        (header) =>
          header.showAdmin === undefined ||
          (header.showAdmin != undefined &&
            header.showAdmin === this.authUser.isAdmin)
      );
    },

    filterNames() {
      // if (this.authUser.isAdmin) {
      //   return ["make_type"];
      // } else {
      return ["search", "country", "state", "city"];
      // }
    },
  },
  watch: {
    options() {
      this.fetchData();
    },
  },
  beforeDestroy() {
    const params = {
      name: "locations",
      filters: this.options,
    };
    this.$store.commit("redirect/SET_FILTERS", params);
  },
  methods: {
    async fetchData() {
      await this.$store
        .dispatch("csmsLocations/list", this.options)
        .then(() => {
          if (this.options.page > (this.meta?.lastPage || 1)) {
            this.options.page = 1;
          }
        });
    },

    // Confirmation of Delete Row
    async deleteSelectedRow(row) {
      try {
        await this.$store.dispatch("csmsLocations/destroy", row);
        this.$store.dispatch("alerts/success", this.$t("deleted_successfully"));
        if (this.list.length === 0 && this.meta.lastPage == this.options.page) {
          this.options.page -= 1; // Move to the previous page
        }
        this.toggleDeleteModal("close");
        this.fetchData();
      } catch (error) {
        this.$store.dispatch("alerts/error", error.response?.data?.message);
      }
    },
    //
    //
    toggelLocationsModal() {
      return (this.locationModal = !this.locationModal);
    },
  },
};
</script>
<style lang="sass" scoped>
.map-button
  height: 41px !important
  margin-right: 5px

.btn-disabled
  pointer-events: none !important
  background-color: #8f8f8f !important

:deep .v-autocomplete.v-text-field--enclosed:not(.v-text-field--solo):not(.v-text-field--single-line):not(.v-text-field--outlined).v-input--dense .v-select__slot > input
  font-size: 0.875rem !important
</style>