export const ListingLayoutMixins = {
  data() {
    return {
      editModal: false,
      deleteModal: false,
      selectedRow: {},
    };
  },
  computed: {
    options() {
      return { ...this.filters, ...this.subFilters };
    },
    //

    // Permissions

    isView() {
      const val = this.permissions.view;
      return val ? this.$admin.can(val) : false;
    },
    isCreate() {
      const val = this.permissions.create;
      return val ? this.$admin.can(val) : false;
    },
    isEdit() {
      const val = this.permissions.edit;
      return val ? this.$admin.can(val) : false;
    },
    isDelete() {
      const val = this.permissions.delete;
      return val ? this.$admin.can(val) : false;
    },
    // Permissions
  },
  methods: {
    onFiltersChange(val) {
      this.filters = val;
      this.subFilters = {};
      if (!this.authUser.isAdmin && this.authUser.user_nature != "fleet") {
        this.filters.tenant_id = this.authUser.tenant_id;
      }
    },

    // =================
    // FOr CRUD Action
    editItem(item) {
      this.selectedRow = { ...item };
      this.toggleEditModal();
    },
    toggleEditModal() {
      return (this.editModal = !this.editModal);
    },
    // Delete Row Section
    // // when delete icon Select
    deleteItem(item) {
      this.selectedRow = { ...item };
      this.toggleDeleteModal("show");
    },
    // // Funtion Invoke When modal Show , Close or Ok
    toggleDeleteModal(v) {
      if (v == "close" || v == "show") {
        return (this.deleteModal = !this.deleteModal);
      } else if (v == "ok") {
        this.deleteSelectedRow(this.selectedRow);
      }
    },
    // Delete Row Section
  },
};
